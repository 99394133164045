import {
  Image as ImageBase,
  ImageCropped as ImageCroppedBase,
  ImageCover as ImageCoverBase
} from '@kaliber/sanity-image'
import { useClientConfig } from '/machinery/ClientConfig'

export function Image({ image, imgProps = {}, layoutClassName = undefined }) {
  const { sanity } = useClientConfig()
  const { baseUrl } = useBaseUrl()

  return (
      <ImageBase
        sanityConfig={{ ...sanity.base, baseUrl }}
        imgProps={{ ...imgProps, alt: image.alt, draggable: false }}
        {...{ image, layoutClassName }}
      />
  )
}

export function ImageCropped({ image, aspectRatio, imgProps = {}, layoutClassName = undefined }) {
  const { sanity } = useClientConfig()
  const { baseUrl } = useBaseUrl()

  return (
    <ImageCroppedBase
      sanityConfig={{ ...sanity.base, baseUrl }}
      imgProps={{ ...imgProps, alt: image.alt }}
      {...{ image, aspectRatio, layoutClassName, draggable: false }}
    />
  )
}

export function ImageCover({ image, aspectRatio, sizes = undefined, imgProps = {}, layoutClassName = undefined }) {
  const { sanity } = useClientConfig()
  const { baseUrl } = useBaseUrl()

  return (
    <ImageCoverBase
      sanityConfig={{ ...sanity.base, baseUrl }}
      imgProps={{ ...imgProps, alt: image.alt, draggable: false }}
      {...{ image, aspectRatio, sizes, layoutClassName }}
    />
  )
}

function useBaseUrl() {
  const { host } = useClientConfig()

  return {
    baseUrl: `${host}/cdn`
  }
}
